<template>
  <section class="page">
    <main>
      <div class="container">
        <router-link class="nav-brand--mobile" to="/">
          <img src="../assets/image/logo/brand-text-logo.svg" alt="brand-text-logo">
          <p>品牌資源中心</p>
        </router-link>
        <div class="page-grid row">
          <div class="column column-12 column-xl-2">
            <Sidebar></Sidebar>
          </div>
          <div class="column column-12 column-xl-8">
            <div class="page-main" ref="pageMain">
              <section id="welcome" class="welcome-block">
                <div class="welcome-block-content page-main-style">
                  <div class="welcome-block-content-text">
                    <h2 class="title-primary h2">歡迎</h2>
                    <p class="info-base">
                      歡迎來到「Ocard for Business 顧客經營管家」的資源中心，若您在報導、推廣或者宣傳 Ocard for Business 顧客經營管家的服務時需要相關素材，您可以在此取用 Ocard for Business 顧客經營管家的品牌資源並查看相關使用規範。
                    </p>
                  </div>
                  <div class="welcome-block-content-img">
                    <img src="../assets/image/logo/OFB-logo.svg" alt="">
                  </div>
                </div>
              </section>
              <section id="brand" class="brand-block">
                <div class="page-main-style">
                  <h2 class="title-primary h2">介紹</h2>
                  <p class="info-base">
                    「Ocard for Business 顧客經營管家」是全台最多知名店家使用的顧客經營系統，讓店家輕鬆打造專屬的顧客忠誠計畫，有效提高回流率。<br />
                    <br />
                    您在此資源中心可獲取的素材包含 Logo、Logotype 及 Logomark。每種素材皆有提供 JPG、PNG、SVG 等檔案類型，方便您根據使用需求來選擇不同的檔案類型。<br />
                    <br />
                    若您製作的是印刷物，請於下載資源後，使用「CMYK」資料夾內的素材。<br />
                    若您製作的是網路多媒體，請於下載資源後，使用「RGB」資料夾內的素材。
                  </p>
                </div>
              </section>
              <section id="guideline" class="guideline-block">
                <div class="page-main-style">
                  <h2 class="title-primary h2">規範</h2>
                  <p class="info-base guideline-text font-weight-semi-bold">
                    <span></span>
                    <span>G1. 當您以文字方式提及 Ocard for Business 時，請您遵照以下規範：</span>
                  </p>
                  <br>
                  <p class="guideline-text correct info-base">
                    <span>Do’s</span>
                    <span>請遵照下列形式</span>
                  </p>
                  <p class="guideline-text info-base">
                    <span></span>
                    <span>G1-a-1. Ocard for Business</span>
                    <span></span>
                    <span>G1-a-2. Ocard for Business 顧客經營管家</span>
                  </p>
                  <br>
                  <p class="guideline-text error info-base">
                    <span>Dont’s</span>
                    <span>請勿以下列形式提及 Ocard for Business</span>
                  </p>
                  <p class="guideline-text info-base">
                    <span></span>
                    <span>G1-b-1. OCard for Business：不正確的大小寫拼字</span>
                    <span></span>
                    <span>G1-b-2. OCARD FOR BUSINESS：不正確的大小寫拼字</span>
                    <span></span>
                    <span>G1-b-3. oCcard For Business：不正確的大小寫拼字</span>
                    <span></span>
                    <span>G1-b-4. ocard for business：不正確的大小寫拼字</span>
                    <span></span>
                    <span>G1-b-5. O card for Business：不正確的空格</span>
                    <span></span>
                    <span>G1-b-6. Ocard for Business顧客經營管家：英文與中文間應加入一半形空格</span>
                  </p>
                  <br><br><br><br>
                  <p class="info-base guideline-text font-weight-semi-bold">
                    <span></span>
                    <span>G2. 當您使用素材檔案時，請您遵照以下規範：<br></span>
                  </p>
                  <br>
                  <p class="guideline-text correct info-base">
                    <span>Do’s</span>
                    <span>請遵照下列形式</span>
                  </p>
                  <p class="guideline-text info-base">
                    <span></span>
                    <span>G2-a-1. 依媒材使用正確的色彩模式檔案</span>
                    <span></span>
                    <span>G2-a-2. 使用最新版本的品牌資源檔案</span>
                    <span></span>
                    <span>G2-a-3. 請以等比例方式縮放素材檔案</span>
                  </p>
                  <br>
                  <p class="guideline-text error info-base">
                    <span>Dont’s</span>
                    <span>請勿以下列方式使用素材檔案</span>
                  </p>
                  <p class="guideline-text info-base">
                    <span></span>
                    <span>G2-b-1. 更動素材檔案顏色</span>
                    <span></span>
                    <span>G2-b-2. 旋轉或翻轉素材檔案</span>
                  </p>
                </div>
              </section>
              <section id="download" class="download-block">
                <div class="page-main-style">
                  <h2 class="title-primary h2">資源下載</h2>
                </div>
              </section>
              <section class="logo-block" ref="logo">
                <ul class="logo-list">
                  <li>
                    <p class="info-base font-weight-semi-bold">Logo</p>
                    <div class="logo-list-img">
                      <img src="../assets/image/logo/OFB-logo.svg" alt="">
                    </div>
                  </li>
                  <li>
                    <p class="info-base font-weight-semi-bold">Logotype</p>
                    <div class="logo-list-img">
                      <img src="../assets/image/logo/OFB-logo-type.svg" alt="">
                    </div>
                  </li>
                  <li>
                    <p class="info-base font-weight-semi-bold">Logomark</p>
                    <div class="logo-list-img">
                      <img src="../assets/image/logo/OFB-logo-mark.svg" alt="">
                    </div>
                  </li>
                </ul>
              </section>
              <section class="agree-block" ref="agree">
                <div class="agree-form">
                  <div class="form-check">
                    <label class="form-check-label check-agree" for="agreeCheck">
                      <input
                        id="agreeCheck"
                        class="check-input"
                        type="checkbox"
                        value=""
                        @click="disabled = (disabled + 1) % 2"
                      >
                      <span class="check-mark"></span>
                      <span class="check-text">我同意遵照規範使用，並瞭解 Ocard 保留更動授權或要求修正之權利</span>
                    </label>
                  </div>
                  <div class="form-download">
                    <button type="button" class="check-download-btn btn btn-primary"
                      :disabled="disabled === 1"
                      @click="download"
                    >
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                          <path d="M19 9.63269H15V3.63269H9V9.63269H5L12 16.6327L19 9.63269ZM11 11.6327V5.63269H13V11.6327H14.17L12 13.8027L9.83 11.6327H11ZM5 18.6327H19V20.6327H5V18.6327Z"
                          fill="#333333"/>
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="24" height="24" fill="white" transform="translate(0 0.63269)"/>
                          </clipPath>
                        </defs>
                      </svg>
                      下載
                    </button>
                  </div>
                </div>
              </section>
              <section id="contact" class="contact-block" ref="contact">
                <div class="page-main-style">
                  <h2 class="title-primary h2">聯繫我們</h2>
                  <p class="info-base">
                    若您使用此中心提供的資源，無需特別提出申請，但必須遵照規範使用。<br />
                    若您對於資源有相關疑問，請點按下方按鈕來聯繫我們，Ocard 將會盡快協助您。
                  </p>
                </div>
                <div class="contact-btn">
                  <button
                    type="button"
                    class="btn"
                    data-bs-target="#contact-modal"
                    @click="openModal()"
                  >
                    聯繫我們
                  </button>
                </div>
                <ContactModal ref="contactModal"></ContactModal>
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <section class="copyright">
        <div class="container">
          <p>Ocard © 2021</p>
        </div>
      </section>
    </footer>
  </section>
</template>

<script>
import ContactModal from '@/components/ContactModal.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
  data() {
    return {
      screenWidth: document.body.clientWidth,
      disabled: 1,
      url: `${process.env.BASE_URL}files/Ocard for Business.zip`,
    };
  },
  components: {
    ContactModal,
    Sidebar,
  },
  mounted() {
    window.onresize = () => (() => {
      window.screenWidth = document.body.clientWidth;
      this.screenWidth = window.screenWidth;
    })();

    this.reSizes();

    document.title = 'Ocard for Business | Ocard 品牌資源中心';
  },
  watch: {
    screenWidth(val) {
      if (!this.timer) {
        this.screenWidth = val;
        this.timer = true;
        setTimeout(() => {
          this.reSizes();

          this.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    download() {
      const blob = new Blob([JSON.stringify({ hello: 'world' })], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const aTag = document.createElement('a');

      aTag.href = this.url;
      aTag.download = 'Ocard for Business';

      aTag.click();

      aTag.href = '';
      URL.revokeObjectURL(url);
    },
    openModal() {
      this.$refs.contactModal.openModal();
    },
    hideModal() {
      this.$refs.contactModal.hideModal();
    },
    reSizes() {
      if (this.screenWidth <= 1024) {
        this.$refs.pageMain.after(this.$refs.contact);
        this.$refs.pageMain.after(this.$refs.agree);
      } else {
        this.$refs.logo.after(this.$refs.contact);
        this.$refs.logo.after(this.$refs.agree);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.contactModal.modal._isShown) {
      next(false);
      this.hideModal();
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/all';

.page {
  margin-top:0;
  @include media-breakpoint-xl {
    margin-top: 32px;
  }
  @include media-breakpoint-xxl {
    margin-top: 102px;
  }

  .nav-brand--mobile {
    display: flex;
    align-items: center;
    padding: 32px 0 16px 0;
    @include media-breakpoint-lg {
      display: none;
    }

    img {
      height: 18px;
      margin-right: 8px;
    }

    p {
      position: relative;
      font-family: $font-family;
      font-size: 18px;
      font-weight: $font-weight-normal;
      color: $info;
      top: 1px;
    }
  }

  .sticky-top {
    position: relative;
    @include media-breakpoint-xl {
      position: sticky;
      position: -webkit-sticky;
      top: 64px;
      left: 0;
    }
  }

  .page-grid {
    justify-content: center;
  }

  .page-main {
    background: white;
    border-radius: $border-radius-md;
    box-shadow: $shadow;
    @include media-breakpoint-lg {
      border-radius: $border-radius-lg;
    }
  }

  .page-main-style {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 3px solid $border;
    @include media-breakpoint-lg {
      padding-bottom: 64px;
      margin-bottom: 64px;
    }

    h2 {
      margin-bottom: 24px;
      @include media-breakpoint-lg {
        margin-bottom: 32px;
      }
    }

    p {
      span {
        display: block;
        @include media-breakpoint-lg {
          display: inline;
        }
        @include media-breakpoint-xxl {
          display: block;
        }
      }
    }

    .info-sm-none  {
      display: none;
      @include media-breakpoint-lg {
        display: block;
      }
    }
  }

  .welcome-block {
    padding: 32px 24px 0 24px;
    @include media-breakpoint-lg {
      padding: 64px 64px 0 64px;
    }

    span {
      display: inline-block;
      @include media-breakpoint-lg {
        display: block;
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-content-text {
      max-width: 540px;
      @include media-breakpoint-xxl {
        max-width: 740px;
      }
    }

    &-content-img {
      display: none;
      text-align: right;
      @include media-breakpoint-lg {
        display: block;
      }
    }
  }

  .guideline-block {
    padding: 0 24px;
    @include media-breakpoint-lg {
      padding: 0 64px;
    }

    .guideline-text {
      display: grid;
      grid-template-columns: 72px auto;
      @include media-breakpoint-md {
        grid-template-columns: 112px auto;
      }
      &.correct {
        color: $correct;
      }
      &.error {
        color: $error;
      }
    }
  }

  .brand-block {
    padding: 0 24px;
    @include media-breakpoint-lg {
      padding: 0 64px;
    }
  }

  .download-block {
    padding: 0 24px;
    @include media-breakpoint-lg {
      padding: 0 64px;
    }

    .page-main-style {
      border-bottom: none;
      margin-bottom: 25px;
      padding-bottom: 0;
    }

    .download-text {
      display: grid;
      grid-template-columns: 72px auto;
      @include media-breakpoint-md {
        grid-template-columns: 112px auto;
      }
      &.correct {
        color: $correct;
      }
      &.error {
        color: $error;
      }
    }
  }

  .logo-block {
    margin-bottom: 28px;
    @include media-breakpoint-md {
      margin-bottom: 64px;
    }

    .logo-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      li {
        position: relative;
        width: 100%;
        padding-top: calc(100% * 1);
        text-align: center;
        @include media-breakpoint-md {
          padding-top: calc(100% * .75);
        }
        @include media-breakpoint-lg {
          width: calc(100% / 3);
          padding-top: calc((100% / 3) * 1);
        }
        &:after {
          content: '';
          border-top: 3px solid $border;
          position: absolute;
          top: 0;
          right: 50%;
          transform: translate(50%, 0);
          width: 334px;
          height: 3px;
          @include media-breakpoint-lg {
            border-right: 3px solid $border;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            width: 3px;
            height: 240px;
          }
        }
        &:last-of-type {
          &:after {
            content: '';
            border-right: none;
          }
        }
      }

      p {
        position: absolute;
        top: 32px;
        left: 50%;
        transform: translateX(-50%);
        color: $gray;
        @include media-breakpoint-lg {
          top: 40px;
          left: 32px;
          transform: translateX(0);
        }
      }

      .logo-list-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          @include media-breakpoint-xl {
            max-width: 150px;
          }
          @include media-breakpoint-xxl {
            max-width: 100%;
          }
        }
      }
    }
  }

  .agree-block {
    padding: 0;
    @include media-breakpoint-lg {
      padding: 0 64px;
      margin-bottom: 136px;
    }

    .agree-form {
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-lg {
        flex-direction: row;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
      }

      .form-check {
        margin-bottom: 32px;
        padding: 20px 0;
        font-size: $font-size-base;
        @include media-breakpoint-md {
          font-size: $font-size-md;
        }
        @include media-breakpoint-lg {
          margin: 0;
          padding: 0;
          margin-right: 32px;
        }
        @include media-breakpoint-xxl {
          font-size: $font-size-lg;
          margin-right: 0;
        }
      }

      .form-check-label {
        display: flex;
        position: relative;
        cursor: pointer;

        .check-input {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
          &:hover {
            ~ .check-text {
              color: $info;
            }

            ~ .check-mark {
              &:before {
                border: 2px solid $body-color;
              }
            }
          }
          &:checked {
            ~ .check-mark {
              &:before {
                opacity: 0;
              }
              &:after {
                opacity: 1;
              }
            }
            ~ .check-text {
              color: $info;
            }
          }
        }

        .check-mark {
          position: absolute;
          top: 0;
          left: 0;
          transition: $animation;
          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 1.5px;
            width: 20px;
            height: 20px;
            @include media-breakpoint-md {
              top: 5px;
            }
            @include media-breakpoint-xxl {
              top: 5px;
            }
          }
          &:before {
            border: 2px solid $gray;
            border-radius: 90%;
            opacity: 1;
          }
          &:after {
            background-size: 20px 20px;
            transform: translate(0px, -1px);
            background-image: url('../assets/image/Icon/Check.svg');
            opacity: 0;
          }
        }

        .check-text {
          font-weight: $font-weight-bold;
          line-height: $line-height-base;
          color: $gray;
          margin-left: 38px;
          transition: $animation;
        }
      }

      .form-download {
        width: 100%;
        cursor: not-allowed;
        margin-bottom: 64px;
        @include media-breakpoint-md {
          width: auto;
        }
        @include media-breakpoint-lg {
          margin-bottom: 0;
        }

        .check-download-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-size: 18px;
          font-weight: $font-weight-bold;
          vertical-align: middle;
          padding: 19px 65px 19px 58px;
          border-color: $primary;
          border-radius: $rounded-pill;
          font-weight: $font-weight-bold;
          cursor: pointer;
          @include media-breakpoint-md {
            width: auto;
          }

          svg {
            margin-top: 1px;
            margin-right: 4px;
          }

          &:disabled {
            color: $gray;
            border-color: #F2F5F8;
            background: $border;
            pointer-events: none;
            @include media-breakpoint-xl {
              border-color: #F2F5F8;
              background: #F2F5F8;
            }

            svg {
              path {
                fill: $gray;
              }
            }
          }
        }
      }
    }
  }

  .contact-block {
    padding: 0;
    @include media-breakpoint-md {
      padding: 0 32px;
    }
    @include media-breakpoint-lg {
      padding: 0 64px 64px 64px;
    }

    .page-main-style {
      padding-bottom: 0;
      border-bottom: none;
    }

    .contact-btn {
      text-align: center;
      @include media-breakpoint-lg {
        text-align: left;
      }
      button {
        width: 100%;
        padding: 19px 64px;
        border: 3px solid $body-color;
        border-radius: $rounded-pill;
        background-color: rgba(0, 0, 0, 0);
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        cursor: pointer;
        transition: $animation;
        @include media-breakpoint-md {
          width: auto;
          margin: 0 auto;
        }
        &:hover {
          color: white;
          background-color: $body-color;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
        }
      }
    }
  }

  .copyright {
    font-size: $font-size-sm;
    text-align: center;
    color: $gray;
    padding: 40px 0;
    @include media-breakpoint-xl {
      padding: 80px 0;
    }
  }
}
</style>
